<template>
    <div class="home-search">
        <form class="home-search__form" @submit.prevent="doSearch">
            <!-- Location -->
            <select v-model="location" class="home-search__input">
                <option disabled selected :value="null"><span v-if="!mobile">Select </span>City</option>
                <option value="*" :disabled="busy" v-if="!property || property == '*'">Select all</option>
                <option v-for="location in filteredLocations" :value="location">{{ location.name }}</option>
            </select>
            <!-- Property -->
            <select v-model="property" class="home-search__input">
                <option disabled selected :value="null"><span v-if="!mobile">Select </span>Property</option>
                <option value="*" :disabled="busy">Select all</option>
                <option v-for="property in filteredProperties" :value="property">{{ property.name }}</option>
            </select>
            <button
                type="submit"
                :disabled="busy || !selectedUrl"
                class="home-search__submit"
                >
                <img :src="`${themeDir}/images/icons/search.svg`" alt="Search" class="home-search__submit-icon" />
                <span class="home-search__submit-text">Search</span>
            </button>
        </form>
    </div>
</template>
<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import axios from 'axios';
import { apiUrl } from '@utils/api';
import { themeDir } from '@utils/theme';

const busy = ref(false);

// Our initial results
const results = ref({
    locations: [],
    properties: [],
    tree: {}
});

// The selected location
const location = ref(null);

// The selected property
const property = ref(null);

// The selected URL
const selectedUrl = ref(null);

watch(property, () => {
    if (property.value && property.value !== "*") {
        location.value = filteredLocations.value[0];
        setTimeout(() => {
            selectedUrl.value = property.value.url;
        });
    } else {
        if (location.value && location.value !== "*") {
            selectedUrl.value = location.value.url;
        } else {
            selectedUrl.value = null;
        }
    }
});

watch(location, () => {
    if (location.value && location.value !== "*") {
        selectedUrl.value = location.value.url;
    } else {
        selectedUrl.value = null;
    }
});

// Get the list of locations as determined by the selected property
const filteredLocations = computed(() => {
    if (property.value && property.value !== "*") {
        const filteredLocationNames = Object.keys(results.value.tree).filter(l => Object.keys(results.value.tree[l]).includes(property.value.name)) ?? [];
        return results.value.locations.filter(l => filteredLocationNames.includes(l.name)) ?? [];
    }
    return results.value.locations ?? [];
});

// Get the list of properties as determined by the selected location
const filteredProperties = computed(() => {
    if (location.value && location.value !== "*") {
        const filteredPropertyNames = Object.keys(results.value.tree[location.value.name]) ?? [];
        return results.value.properties.filter(p => filteredPropertyNames.includes(p.name)) ?? [];
    }
    return results.value.properties ?? [];
});

// Fetch our initial set of results - we only need to do this once as it should
// contain all of the data we need to derive the URLs and the relationships
const fetchResults = async () => {
    if (busy.value) {
        return;
    }
    busy.value = true;
    const response = await axios.get(`${apiUrl}/search-smart`, {
        params: {
            search: ""
        }
    });
    busy.value = false;
    results.value = response.data;
};

// Submit the search form - in this case, we just go to the selected URL
const doSearch = async () => {
    if (selectedUrl.value) {
        window.location.href = selectedUrl.value;
    }
};

const mobile = computed(() => {
    return window.innerWidth < 600;
});

// Fetch the initial results to populate the lists and allow us to derive the
// relationships and URLs
onMounted(() => {
    fetchResults();
});
</script>