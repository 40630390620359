import { createApp } from "vue";

import HomeSearch from "./HomeSearch.vue";

const handle = "almero-vue-home-search";
const mountEls = document.querySelectorAll(`.${handle}`);


mountEls.forEach((mountEl) => {
    let props = {};
    try {
        props = JSON.parse(mountEl.dataset.rootProps);
    }
    catch (e) {
        console.error(e);
    }
    const app = createApp(
        HomeSearch, {
            ...props,
        }
    );

    app.component("HomeSearch", HomeSearch);

    app.mount(mountEl);
});
